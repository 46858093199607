<template>
      <v-app class>
        <div>
          <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
    </v-alert>
        </div>
        <div class="row">
          <div class="col-md-10 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>Allowances</h1>
              </div>
              <div class="row">
                <div class="col-md-12 d-flex justify-start">
                  <div class="row">
                    <div class="col-md-3">
                      <SelectField
                        :items="salaryPeriods"
                        v-model="formData.salaryPeriod"
                        :label="'Salary Cycle'"
                        :itemText="'description'"
                        :itemValue="'id'"
                        :returnObject="true"
                        @onChange="changeSalaryPeriod"
                      />
                    </div>
                    <div class="col-md-3">
                      <SelectField
                        :items="paymentStatus"
                        v-model="payment"
                        :label="'Payment Status'"
                        :itemText="'name'"
                        :itemValue="'id'"
                        :returnObject="true"
                        @onChange="changePaymentStatus"
                      />
                    </div>
                    <div class="col-md-3">
                        <TextField v-model="refPayment" :label="'Payment Ref.'" />
                    </div>
                    <div>
                      <Button :btnType="'Submit'" :isLoading="isSubmit" :label="'Submit'" @onClick="onSubmit" class="col-md-3"/>
                    </div>
                  </div>
                </div>

              </div>
                <div class="col-md-8 d-flex justify-start">
                  <div class="row">
                  <div class="col-md-4">
                      <v-autocomplete
                        :items="contractEmployers"
                        v-model="contractEmployerId"
                        outlined
                        dense
                        item-text="name"
                        label="Contractor Employer"
                        item-value="id"
                        @change="contractEmployerChange"
                      ></v-autocomplete>
                  </div>
                  <div class="col-md-6 export-container">
                    <SelectField :items="downloadFormat" v-model="selectedFormat" :label="'Download Format'" :itemText="'name'" :itemValue="'id'" :returnObject="true" @input="onSelectedFormat" />
                    <Button :btnType="'Submit'" :isLoading="isDownloading" :label="'Export'" @onClick="onDownload" class="col-md-3 mr-2"/>
                  </div>
                </div>
                </div>
              <div v-if="loadingData" class="text-center">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
             <v-data-table
              :headers= 'headers'
              :items="filteredAllowanceRecords"
             >
             </v-data-table>
              <table hidden id="allowanceTable">
                <thead>
                  <tr>
                    <th>Personnel Name</th>                    
                    <th>Contractor Employer</th>
                    <th>Allowance Name</th>
                    <th>Salary Cycle</th>
                    <th>Basis</th>
                    <th>Total</th>
                    <th>Reference</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in filteredAllowanceRecords" :key="item.requestId">
                      <td>
                        {{item.employeeName}}
                      </td>                      
                      <td>
                        {{item.employer}}
                      </td>
                      <td>
                        {{item.allowance}}
                      </td>
                      <td>
                        {{item.salaryPeriodDescription}}
                      </td>
                      <!-- <td>
    {{item.approvalStatus}}
  </td> -->
                      <td>
                        {{item.frequency}}
                      </td>
                      <td>
                        {{item.total}}
                      </td>
                      <td>
                        {{item.paymentBatchNumber}}
                      </td>
                    </tr>
                  </tbody>
              </table>
              <!-- confirm delete modal  -->
              <Dialog ref="deleteModal" :title="'Are you sure you want to delete'" :width="400">
                <template v-slot:footer>
                  <Button :btnType="'Submit'" :label="'Yes'" @onClick="deleteJobtitle" class="mr-2"/>
                  <Button :btnType="'Cancel'" :label="'No'" @onClick="cancel('deleteModal')" />
                </template>
              </Dialog>
            </v-container>
          </div>
        </div>
      </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import Dialog from '@/components/ui/Dialog.vue'
import { requestService, salaryPeriodService, contractEmployerService } from '@/services'
import ExportToCSVFile from '@/utils/helper'
import moment from 'moment'
import XLSX from 'xlsx'
import JSPDF from 'jspdf'
import 'jspdf-autotable'
// import { UPDATE_JOBTITLE, FETCH_EMPLOYEES, DELETE_JOBTITLE } from '@/store/action-type'
export default {
  components: {
    SelectField,
    Button,
    Dialog,
    TextField
  },
  data () {
    return {
      contractEmployerId: 0,
      contractEmployers: [],
      isDownloading: false,
      isSubmit: false,
      loadingData: false,
      search: '',
      formData: {
        name: '',
        id: '',
        salaryPeriod: ''
      },
      payment: '',
      refPayment: '',
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      allowanceRecords: [],
      filteredAllowanceRecords: [],
      salaryPeriods: [],
      headers: [
        {
          text: 'Personnel Name',
          value: 'employeeName'
        },
        {
          text: 'Contractor Employer',
          value: 'employer'
        },
        {
          text: 'Allowance Name',
          value: 'allowance'
        },
        {
          text: 'Salary Cycle',
          value: 'salaryPeriodDescription'
        },
        {
          text: 'Frequency',
          value: 'frequency'
        },
        {
          text: 'Total',
          value: 'total'
        },
        {
          text: 'Reference',
          value: 'paymentBatchNumber'
        }
      ],
      paymentStatus: [
        { id: 0, name: 'Unpaid' }, { id: 1, name: 'Paid' }
      ],
      downloadFormat: [
        {
          name: 'Excel',
          id: 'excel'
        },
        {
          name: 'PDF',
          id: 'pdf'
        },
        {
          name: 'CSV',
          id: 'csv'
        }
      ],
      selectedFormat: ''
    }
  },
  computed: {
    // employees () {
    //   return this.$store.getters.employees
    // }
  },
  methods: {
    addNew () {
      // this.$router.push('/medical/add')
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    openDeleteModal (item) {
      this.$refs.deleteModal.toggleModal()
      this.formData.name = item.name
      this.formData.id = item.id
    },
    deleteJobtitle () {
      // this.$store.dispatch(DELETE_JOBTITLE, this.formData).then((result) => {
      //   this.showAlertMessage('Jobtitle deleted successfully', 'success')
      //   // TODO remove this console.log
      // }).catch(error => {
      //   console.log(error.message)
      //   // TODO please put an alert component here
      // })
    },
    cancel (modal) {
      if (modal === 'deleteModal') {
        this.$refs.deleteModal.toggleModal()
      }
    },
    onSelectedFormat (value) {
      this.selectedFormat = value.id
    },
    onSubmit () {
      this.isSubmit = true
      this.allowanceRecords = []

      // if (this.refPayment !== '') {
      const data = {
        SalaryPeriodId: this.formData.salaryPeriod.id,
        PaymentStatus: this.payment.id,
        PaymentReference: this.refPayment
      }

      requestService.getApprovedAllowance(data).then(result => {
        if (result.data.length > 0) {
          result.data.forEach(item => {
            const requestItems = item.requestInfo
            let requestDetails = ''
            let totalCount = requestItems.length
            let itemsTotalValue = 0

            if (requestItems.length > 0) {
              requestItems.forEach(request => {
                if (requestDetails !== '') requestDetails += ', '

                if (isNaN(request.value)) {
                  if (request.requestDate !== null) {
                    requestDetails += moment(request.requestDate).format('MMM Do')
                  }
                }

                if (!isNaN(request.value)) {
                  requestDetails += `${moment(request.requestDate).format('MMM Do')} (${request.value})`
                  itemsTotalValue += Number(request.value)
                }
              })
            }

            this.allowanceRecords.push({
              employeeName: item.employeeName,
              allowance: item.allowance,
              // approvalStatus: item.approvalStatus,
              // dates: requestDetails,
              // comment: item.computed,              
              employer: item.contractEmployerName,
              // employerId: item.contractEmployerId,
              // total: itemsTotalValue === 0 ? totalCount : itemsTotalValue,
              total: item.summary,
              frequency: item.frequency,
              paymentBatchNumber: item.paymentBatchNumber,
              salaryPeriodDescription: item.salaryPeriodDescription
            })
          })
        }
        this.filteredAllowanceRecords = this.allowanceRecords
      }).catch(error => {
        this.showAlertMessage(error.message, 'error')
      }).finally(() => {
        this.isSubmit = false
      })
      // } else {
      //  this.isSubmit = false
      //  this.showAlertMessage('Payment Reference required.', 'error')
      // }
    },
    onDownload () {
      if (this.selectedFormat !== '') {
        try {
          this.isDownloading = true
          const time = moment().format()
          switch (this.selectedFormat) {
            case 'pdf':
              const doc = new JSPDF('landscape')
              doc.autoTable({
                theme: 'striped',
                headStyles: { fillColor: '#0B2D71', textColor: '#fff' },
                alternateRowStyles: { fillColor: '#F3F4F8' },
                html: '#allowanceTable'
              })

              doc.text('Approved Allowances - CMMS', 150, 10, { align: 'center' })
              // doc.text('L - Leave', 150, 200, { align: 'center' })
              doc.save(`ApprovedAllowances_${time}.pdf`)
              this.isDownloading = false
              break
            case 'excel':
              var allowanceSheet = XLSX.utils.json_to_sheet(this.filteredAllowanceRecords)
              var allowanceWorkBook = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(allowanceWorkBook, allowanceSheet, 'ApprovedAllowances')
              XLSX.writeFile(allowanceWorkBook, `ApprovedAllowances_${time}.xlsx`)
              this.isDownloading = false
              break
            case 'csv':
              const formattedData = []
              const fileHeaders = {
                employeeName: 'Personnel Name',
                allowance: 'Allowance Name',
                approvalStatus: 'Approval Status',
                total: 'Total',
                frequency: 'Frequency',
                paymentBatchNumber: 'Payment Reference',
                salaryPeriodDescription: 'Salary Cycle'

              }
              this.filteredAllowanceRecords.forEach(item => {
                formattedData.push({
                  employeeName: item.employeeName.replace(/,/g, ''),
                  allowance: item.allowance.replace(/,/g, ''),
                  approvalStatus: item.approvalStatus,
                  total: item.summary,
                  frequency: item.frequency,
                  salaryPeriodDescription: item.salaryPeriodDescription,
                  paymentBatchNumber: item.paymentBatchNumber
                })
              })

              ExportToCSVFile(fileHeaders, formattedData, `ApprovedAllowances_${time}`)
              this.isDownloading = false
              break
            default:
              break
          }
        } catch (error) {
          this.isDownloading = false
        }
      }
    },
    formatAmount (value) {
      return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    changeSalaryPeriod () {
      // this.getApprovedAllowance(this.formData.salaryPeriod.id)
    },
    changePaymentStatus () {
      // this.getApprovedAllowance(this.formData.salaryPeriod.id, this.payment.id)

      // console.log('Paymet Status', this.payment.id)
      // requestService.paymentStatus(this.payment.id, this.formData.salaryPeriod.id).then((result) => {
      //   this.showAlertMessage('Successful', 'success')
      //   console.log('Result', result.data)
      // }).catch((error) => {
      //   console.log(error)
      //   this.showAlertMessage('Failed', 'error')
      // }).finally(() => {

      // })
    },
    getApprovedAllowance (salaryPeriodId, paymentStatusId) {
      this.loadingData = true
      this.allowanceRecords = []
      requestService.getApprovedAllowance(salaryPeriodId, paymentStatusId).then(result => {
        if (result.data.length > 0) {
          result.data.forEach(item => {
            const requestItems = item.requestInfo
            let requestDetails = ''
            let totalCount = requestItems.length
            let itemsTotalValue = 0

            if (requestItems.length > 0) {
              requestItems.forEach(request => {
                if (requestDetails !== '') requestDetails += ', '

                if (isNaN(request.value)) {
                  if (request.requestDate !== null) {
                    requestDetails += moment(request.requestDate).format('MMM Do')
                  }
                }

                if (!isNaN(request.value)) {
                  requestDetails += `${moment(request.requestDate).format('MMM Do')} (${request.value})`
                  itemsTotalValue += Number(request.value)
                }
              })
            }

            this.allowanceRecords.push({
              employeeName: item.employeeName,
              allowance: item.allowance,
              approvalStatus: item.approvalStatus,
              // dates: requestDetails,
              comment: item.computed,              
              employer: item.contractEmployerName,
              employerId: item.contractEmployerId,
              // total: itemsTotalValue === 0 ? totalCount : itemsTotalValue,
              total: item.summary,
              frequency: item.frequency,
              paymentBatchNumber: item.paymentBatchNumber,
              salaryPeriodDescription: item.salaryPeriodDescription
            })
          })
        }
        this.filteredAllowanceRecords = this.allowanceRecords
      }).catch(error => {
        this.showAlertMessage(error.message, 'error')
      }).finally(() => {
        this.loadingData = false
      })
    },
    contractEmployerChange (item) {
      this.filteredAllowanceRecords = []
      this.isLoading = true
      if (item === 0.1) {
        this.filteredAllowanceRecords = this.allowanceRecords
        return
      }

      const searchRecords = this.allowanceRecords.filter(allowance => allowance.employerId === item)
      this.filteredAllowanceRecords = searchRecords
      this.isLoading = false
    }
  },
  mounted () {
    // this.getApprovedAllowance()

    salaryPeriodService
      .getAllSalaryPeriod()
      .then(result => {
        this.salaryPeriods = result.data
      })
      .catch(() => {})

    contractEmployerService.getAllContractEmployers().then((result) => {
      this.contractEmployers = result.data.items
      if (result.data.items.length > 1) {
        this.contractEmployers.unshift({ 'id': 0.1, 'name': 'All' })
      }
    })
  }
}
</script>
<style scoped>
.export-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
